import React, {useEffect, useState} from 'react';

const DiscordAdminGuide = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const scrollToSection = (index) => {
        const section = document.getElementById(`section-${index}`);
        if (section) {
            window.location.hash = `#${guideParts[index].toLowerCase().replace(/\s+/g, '-')}`;
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const guideParts = [
        'Being part of the team',
        'Activity',
        'Responsibilities',
        'Requirements',
        'Punishments',
        'Useful Tools'
    ];

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1); // Remove '#' from hash
            const index = guideParts.findIndex(part => part.toLowerCase().replace(/\s+/g, '-') === hash);
            if (index !== -1) {
                scrollToSection(index);
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        // Scroll to the correct section if there is a hash in the URL
        const hash = window.location.hash.substring(1);
        const index = guideParts.findIndex(part => part.toLowerCase().replace(/\s+/g, '-') === hash);
        if (index !== -1) {
            scrollToSection(index);
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-800 min-h-screen p-4"}>
            <div className={"max-w-screen-lg mx-auto p-4 text-center"}>
                <div className="mb-2">
                    <button className={`w-full text-left p-4 bg-zinc-700 0 flex justify-between items-center ${isOpen ? 'rounded-t-xl' : 'rounded-xl'}`} onClick={toggleAccordion}>
                        <h1 className={"text-center text-2xl lg:text-3xl lg:text-left text-amber-600 font-cubano"}>Sections of the Discord admin guide</h1>
                    </button>
                    {isOpen && (
                        <div className="p-4 bg-zinc-800">
                            <ul className="list-disc list-inside text-left">
                                {guideParts.map((value, index) => (
                                    <li className="text-white text-lg font-semibold cursor-pointer" onClick={() => scrollToSection(index)}>{value}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className={"max-w-screen-lg mx-auto p-4 text-left"}>
                <section id={"section-0"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Being part of the team</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-2"}>Being an in-game admin automatically means the admin is part of the staff team. The admin should demonstrate ability to be cooperative and talkative with the rest of the team.</li>
                                <li className={"mb-2"}>When the admin is not sure how to solve a particular situation, they should ask other members of the team for an assistance.</li>
                                <li className={"mb-2"}>It's expected that the admin will be loyal to other staff members and discuss any disagreements if they arise, with a respect and a clear goal of resolving them.</li>
                                <li className={"mb-2"}>Admins are anticipated to utilize the appropriate staff channels for their communication.</li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-1"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Activity</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-2"}>Bearing the role of an admin holds significant responsibilities, emphasizing the need for consistent activity.</li>
                                <li className={"mb-2"}>There is no required amount of messages or hours spent in voice channels the admin meet per month, but being active, engaging with the members and helping them on the Discord server is most important.</li>
                                <li className={"mb-2"}>Each admin must promptly express their views on critical staff discussions. Failure to meet this expectation may result in warnings, and repeated negligence could lead to potential removal from the role.</li>
                                <li className={"mb-2"}>Failure to notify the team in advance of an extended absence, coupled with misusing time off, could lead to the removal of the admin's position.</li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-2"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Responsibilities</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-2"}>Each admin is accountable for their actions. Regardless of unfamiliarity with the rules or admin guide, these factors do not serve as valid excuses for one's actions.</li>
                                <li className={"mb-2"}>It is the admin's responsibility to ensure that all player reports, ban appeals, and admin abuse reports are exclusively handled through the ticket system on BF4C Discord. Members must be directed to create a ticket if they seek resolution through other means.</li>
                                <li className={"mb-2"}>Admins are required to promptly address any support ticket while online. However, if the ticket doesn't directly concern their actions, they should avoid further involvement. In such cases, they can be consulted by another admin for additional information, and if needed, they can directly provide a response to the ticket.</li>
                                <li className={"mb-2"}>It's important to note that should the admin possess biases that could affect their judgment, they should avoid handling the ticket altogether. Ensuring fairness and impartiality is essential in addressing support tickets.</li>
                                <li className={"mb-2"}>When possible, admins should manage BF4C platoon tickets. Admins can assist members throughout the process, aiding them in joining the platoon and ensuring they have the necessary clantag set. Once confirmed, the admin can utilize the /platoon accept and /vip activate commands to provide the member with information about benefits.</li>
                                <li className={"mb-2"}>The admin should make sure that the channels are always welcoming and that everyone abides the rules.</li>
                                <li className={"mb-2"}>Every admin has to represent our community by wearing the BF4C clan tag while playing on BF4C servers.</li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-3"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Requirements</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-decimal ml-6 mt-4"}>
                                <li className={"mb-2"}>The admin <strong>must</strong> have the Discord server unmuted. Individual channels that are irrelevant to their position can be muted.</li>
                                <li className={"mb-2"}>Having Developer mode enabled is required. Learn how to <a className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600" href={"https://www.howtogeek.com/714348/how-to-enable-or-disable-developer-mode-on-discord/"}>enable it</a>.</li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section id={"section-4"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Punishments</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full">
                            <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="px-6 py-3 text-left">Punishment</th>
                                <th className="px-6 py-3 text-left">Description</th>
                            </tr>
                            </thead>
                            <tbody className="text-gray-100">
                            <tr>
                                <td className="px-6 py-4">1. Warn</td>
                                <td className="px-6 py-4">Warn the member using DMs. Optionally, delete the sent messages too.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">2. 1-week timeout</td>
                                <td className="px-6 py-4">Timeout the member for one week.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">3. Kick</td>
                                <td className="px-6 py-4">Kick the member from the Discord server.</td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4">4. Ban</td>
                                <td className="px-6 py-4">Ban the member from the Discord server. Do not delete member message history.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className={"text-base text-gray-100 font-sofia-pro"}>
                        <ol className={"list-disc ml-6 mt-4"}>
                            <li className={"mb-2"}>The standard punishment order shown above has to be utilized by the admin at all times.</li>
                            <li className={"mb-2"}>Special situations below might be handled differently as described per each situation.</li>
                        </ol>
                    </p>
                    <div className={"mt-4"}>
                        <h2 className={"text-xl text-blue-400 font-roboto"}>Member supports Homophobia, Racism, Nazism, Sexism, Xenophobia, and other forms of bigotry in any shape or form.</h2>
                        <p className={"text-base text-gray-100 font-sofia-pro"}>Ban the member.</p>
                    </div>
                    <div className={"mt-4"}>
                        <h2 className={"text-xl text-blue-400 font-roboto"}>Member posts illegal topics such as drug use or condoning piracy</h2>
                        <p className={"text-base text-gray-100 font-sofia-pro"}>Delete the messages and kick the member. If they continue after rejoining, ban the member.</p>
                    </div>
                </section>

                <section id={"section-5"} className="mb-8">
                    <h2 className="text-3xl lg:text-4xl text-emerald-500 font-cubano mb-4">Useful Tools</h2>
                    <div className="overflow-x-auto">
                        <p className={"text-base text-gray-100 font-sofia-pro"}>
                            <ol className={"list-disc ml-6 mt-4"}>
                                <li className={"mb-2"}><a href="https://discord.id/" className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600">Discord ID</a></li>
                            </ol>
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default DiscordAdminGuide;
