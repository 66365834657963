import React, {useEffect} from "react";

import AOS from "aos";
import 'aos/dist/aos.css';

const AdminRecruitment = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-800 min-h-screen p-4"}>
            <div className={"max-w-screen-lg mx-auto"}>
                <h1 className={"text-5xl font-cubano text-blue-600 md:text-6xl"}>Admin Recruitment</h1>
                <p className="text-lg font-sofia-pro text-white">
                    We are always looking for new staff members to join our team. If you are interested in helping out, please take a look at the available positions below. If you think you have what it takes, don't hesitate to apply!
                </p>

                <img
                    src={require("../Images/OneTwoThree.jpg")}
                    alt={"Admin Recruitment"}
                    className={"w-full h-auto rounded-lg shadow-lg mt-12"}
                />
            </div>

            <div className={"max-w-screen-lg mx-auto"}>
                <h1 className={"text-3xl font-cubano text-green-500 mt-12"}>What are the expectations?</h1>
                <p className={"text-lg sm:text-left font-sofia-pro text-white"}>It's expected that the candidate is known in the community and is active in on our in-game servers and Discord. The candidate should be able to work in a team and be able to communicate effectively.</p>

                <h1 className={"text-3xl font-cubano text-green-500 mt-4"}>How to apply?</h1>
                <p className={"text-lg sm:text-left font-sofia-pro text-white"}>Join our Discord server and create new ticket in the #admin-apply channel. A new ticket will be created with questions that you will need to answer. Please take your time to answer them as best and in as much detail as you can.</p>

                <h1 className={"text-3xl font-cubano text-green-500 mt-4"}>When will I get a response?</h1>
                <p className={"text-lg sm:text-left font-sofia-pro text-white"}>All the applications are reviewed within 48 hours. You will receive the verdict in the very same ticket you applied in.</p>
            </div>

            <div className={"max-w-screen-lg mx-auto"}>
                <h2 className={"text-5xl font-cubano text-blue-600 mt-12 md:text-6xl"}>Opened Positions</h2>

                <div className={"bg-zinc-900 rounded-lg shadow-lg p-4 mt-4"}>
                    <h3 className={"text-4xl font-cubano text-amber-500"}>BF4C In-game Server Admin</h3>
                    <p className={"text-lg font-sofia-pro text-gray-100"}>Prepare to take on an exciting role supervising our BF4 servers! Your primary responsibility is to ensure that everyone follows the rules. Along with helping with support tickets, you will also spend time on Discord interacting with the BF4C community. Consider yourself the expert who understands how to deal with rule-breakers, how to keep the servers safe, and how the servers work. Working closely with the rest of the team will transform you into more than just a rule enforcer; you will be a friendly face in our close-knit crew!</p>

                    <h3 className={"text-3xl font-cubano text-emerald-400 mt-4"}>What are the requirements?</h3>
                    <ul className={"text-lg font-sofia-pro text-gray-100 list-disc pl-4"}>
                        <li>The candidate should be known for making valid player reports</li>
                        <li>Being a member of the BF4C Discord server and being active in it</li>
                        <li>50 hours of playtime on our in-game servers</li>
                        <li>Extensive knowledge of the game mechanics. Required stats: 1,5 KDR, 0.7 KPM</li>
                    </ul>

                    <h3 className={"text-3xl font-cubano text-emerald-400 mt-4"}>Career growth</h3>
                    <p className={"text-lg font-sofia-pro text-gray-100"}>There are 3 additional positions that you can progress to: <strong>Discord Admin</strong>, <strong>Writer</strong>, and <strong>Programmer</strong>. After 3 months of being an In-game Admin, based on the interest, one can discuss of being promoted to one of the mentioned positions.</p>

                    <h3 className={"text-3xl font-cubano text-emerald-400 mt-4"}>Guide</h3>
                    <p className={"text-lg font-sofia-pro text-gray-100"}>Please read <a href={"/ingame-admin-guide"} target={"_blank"} rel={"noreferrer"} className={"text-blue-500"}>the guide</a> before applying. It will help you understand the responsibilities and the expectations of the position.</p>

                    <button className={"bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4 transition duration-300 ease-in-out hover:bg-blue-600"} onClick={
                        () => {
                            window.open("https://discord.gg/bf4c", "_blank");
                        }
                    }>Apply now!</button>
                </div>
            </div>
        </div>
    )
}

export default AdminRecruitment;
