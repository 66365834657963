import {useEffect} from "react";

const NotFound = () => {
    useEffect(() => {
        document.title = "404 - Not Found";
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-700 min-h-screen p-4"}>
            <div className={"text-center"}>
                <h1 className={"text-9xl text-amber-600 font-attribute-mono font-bold"}>404</h1>
                <p className={"text-2xl text-gray-100 mb-6"}>What are looking for does not exist.</p>
                <a href={"/"} className={"text-2xl font-cubano text-white bg-blue-700 p-2 shadow-2xl rounded hover:bg-blue-800 transition-transform"}>Take me home.</a>
                <img
                    src={require("../Images/SilkRoadBike.jpg")}
                    alt={"404"}
                    className={"w-1/2 rounded shadow-xl mt-12 justify-center mx-auto"}
                />
            </div>
        </div>
    )
}

export default NotFound;