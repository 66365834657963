import React, {useState, useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const VIP = () => {
    const [selectedTab, setSelectedTab] = useState('paypal');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <div className={"bg-gradient-to-b from-zinc-900 to-zinc-700 min-h-screen p-4"}>
            <div className={"max-w-screen-lg mx-auto p-4 text-center"}>
                <h1 className={"text-5xl font-cubano text-amber-600 md:text-6xl"}>BF4C VIP</h1>
                <h2 className={"text-xl font-attribute-mono text-white"}>Support our hard work and get rewarded in return!</h2>

                <img
                    src={require("../Images/SilkRoadTeamwork.jpg")}
                    alt={"VIP"}
                    className={"w-full h-auto rounded-lg shadow-lg mt-12"}
                />

                <h1 className={"text-4xl font-cubano text-amber-400 mt-24 md:text-5xl"}>What are the benefits?</h1>
                <h2 className={"text-lg font-attribute-mono text-white"}>VIP comes with 13 benefits! See them all in detail below.</h2>
            </div>

            <div data-aos="fade-up" className="container mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="bg-gradient-to-b from-zinc-600 to-zinc-700 p-4 rounded-lg shadow-md hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-4xl text-center font-cubano font-semibold text-emerald-600">Commands</h2>
                        <ol className={"list-decimal text-lg text-white ml-4 mt-4"}>
                            <li className={"mb-2"}><strong>/lead</strong> | Lets you become a squad leader.</li>
                            <li className={"mb-2"}><strong>/moveme</strong> | Immediatelly moves you to the opposite team.</li>
                            <li className={"mb-2"}><strong>/killme</strong> | Kills you.</li>
                            <li className={"mb-2"}><strong>/msg nickname message</strong> | Sends a private message to a player you specify.</li>
                            <li className={"mb-2"}><strong>/battlecry</strong> | Displays a custom message when you join the server.</li>
                            <li className={"mb-2"}><strong>/join</strong> | Allows you to join your friend's squad instantly.</li>
                        </ol>
                    </div>

                    <div className="bg-gradient-to-b from-zinc-600 to-zinc-700 p-4 rounded-lg shadow-md hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-4xl text-center font-cubano font-semibold text-emerald-600">Perks</h2>
                        <ol className={"list-decimal text-lg text-white ml-4 mt-4"}>
                            <li className={"mb-2"}><strong>Triple votemap power</strong> | Your vote will have 3x power.</li>
                            <li className={"mb-2"}><strong>Auto-balance whitelist</strong> | Protects you from getting moved.</li>
                            <li className={"mb-2"}><strong>Queue priority</strong> | Places you first in the server queue.</li>
                            <li className={"mb-2"}><strong>Sniper/DMR whitelist</strong> | Lets you play with Sniper and DMR rifles at any time.</li>
                            <li className={"mb-2 line-through"}><strong>VPN whitelist</strong> | Protects you from being kicked for using VPN.</li>
                            <li className={"mb-2"}><strong>Discord role</strong> | Special Discord role shows your much appreciated support.</li>
                            <li className={"mb-2"}><strong>Farm limit</strong> | Allows you to get up 50 kills when the farm limit is active.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" className={"text-center mt-24"}>
                <h1 className={"text-4xl font-cubano text-amber-400 mt-24 md:text-5xl"}>What's the pricing?</h1>
            </div>

            <div data-aos="fade-up" className="container mx-auto p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="bg-blue-500 text-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-2xl font-semibold mb-2">1 Month</h2>
                        <p className="text-lg mb-2 font-attribute-mono">€ 3.0</p>
                        <p className={"text-sm"}>One-time payment. The VIP subscription can be extended at any time or reactivated after it has expired.</p>
                    </div>

                    <div className="bg-green-500 text-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-2xl font-semibold mb-2">3 Months</h2>
                        <p className="text-lg mb-2 font-attribute-mono">€ 8.25</p>
                        <p className={"text-sm"}>One-time payment. The VIP subscription can be extended at any time or reactivated after it has expired.</p>
                    </div>

                    <div className="bg-yellow-500 text-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-2xl font-semibold mb-2">6 Months</h2>
                        <p className="text-lg mb-2 font-attribute-mono">€ 15.50</p>
                        <p className={"text-sm"}>One-time payment. The VIP subscription can be extended at any time or reactivated after it has expired.</p>
                    </div>

                    <div className="bg-red-500 text-white p-4 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer">
                        <h2 className="text-2xl font-semibold mb-2">12 Months</h2>
                        <p className="text-lg mb-2 font-attribute-mono">€ 30.0</p>
                        <p className={"text-sm"}>One-time payment. The VIP subscription can be extended at any time or reactivated after it has expired.</p>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" className="container mx-auto p-4">
                <div className="text-center mt-24">
                    <h1 className="text-4xl font-cubano text-amber-400 mt-24 md:text-5xl">What are the payment options?</h1>
                    <h2 className="text-lg font-attribute-mono text-white">
                        Currently we accept PayPal and Discord Server Boosting.
                    </h2>
                </div>

                <div data-aos="fade-up" className="container mx-auto p-4">
                    <div className="flex flex-wrap -mx-4 justify-center">
                        <div className="w-full sm:w-1/2 md:w-1/2 px-4 mb-4 text-center">
                            <div className="tab-container flex-col items-center">
                                <button
                                    className={`bg-zinc-800 p-4 text-white text-4xl rounded-lg shadow-lg border-2 border-blue-600 font-cubano tab-button hover:text-blue-600 m-2 transition-all ${selectedTab === 'paypal' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('paypal')}>
                                    PayPal
                                </button>
                                <button
                                    className={`bg-zinc-800 p-4 text-white text-4xl rounded-lg shadow-lg border-2 border-indigo-600 font-cubano tab-button hover:text-indigo-600 m-2 transition-all ${selectedTab === 'discord' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('discord')}>
                                    Discord Boost
                                </button>
                            </div>
                            {selectedTab === 'paypal' && (
                                <div>
                                    <p className="text-xl text-white mt-2 font-sofia-pro">To donate using PayPal, you
                                        can proceed <a href="https://paypal.me/bf4c"
                                                       className="text-blue-500 border-b-2 border-blue-500 hover:text-blue-600 transition-all">here</a>.
                                    </p>
                                    <p className="text-2xl text-red-600 mt-2 font-cubano">Donations towards specific server are possible. Please specify the server in the payment note.</p>
                                </div>
                            )}
                            {selectedTab === 'discord' && (
                                <>
                                    <ol className="text-left">
                                        <li className="text-xl text-white mt-2 font-sofia-pro list-decimal">Members
                                            of the <a href="https://discord.bf4c.net"
                                                      className="text-emerald-500 border-b-2 border-emerald-500 hover:text-emerald-400 transition-all">BF4C
                                                Discord server</a> can boost the server and get a VIP in return.
                                        </li>
                                        <li className="text-xl text-white mt-2 font-sofia-pro list-decimal">1 boost
                                            is equal to 15 days of VIP. After boosting, reach out through the ticket
                                            system to get your VIP.
                                        </li>
                                        <li className="text-xl text-white mt-2 font-sofia-pro list-decimal">Leaving the
                                            Discord server or cancelling the boost before the end of the VIP period will
                                            result in losing the VIP.
                                        </li>
                                    </ol>
                                    <p className="text-2xl text-red-600 mt-4 font-cubano list-decimal">This type
                                        of VIP can be activated only once per month!</p>
                                </>
                            )}
                        </div>
                    </div>
                    <p className={"text-white text-center text-lg font-sofia-pro"}>By submitting the payment, the
                        buyer confirms they have read and agreed to all provided information.</p>
                </div>
            </div>

            <div className="container mx-auto p-4">
                <div className="flex flex-col cursor-pointer">
                    <div data-aos={"fade-right"} className="border-2 border-amber-600 rounded-lg p-4 mb-4 pl-8">
                        <h1 className="text-3xl font-cubano text-amber-400 mb-2">What are we using donations for?</h1>
                        <p className="text-lg font-attribute-mono text-white">
                            All donations are only used to keep the servers online and populated. This does not include
                            other backend infrastructure. Your support helps us maintain the servers.
                            Please note that we may have to shut down BF4C servers if we run out of funds.
                        </p>
                    </div>

                    <div data-aos={"fade-right"} className="border-2 border-amber-600 rounded-lg p-4 mb-4 pl-8">
                        <h1 className="text-3xl font-cubano text-amber-400 mb-2">Server expenses</h1>
                        <p className="text-lg font-attribute-mono text-white">
                            BF4C 1 server costs: 60,04€ monthly.<br/>
                            BF4C 2 server costs: 72,91€ monthly.<br/><br/>
                            When the BF4C 1 hits its monthly goal, the remaining funds will are used to cover the BF4C 2
                            server expenses.
                        </p>
                    </div>

                    <div data-aos={"fade-right"} className="border-2 border-amber-600 rounded-lg p-4 mb-4 pl-8">
                        <h1 className="text-3xl font-cubano text-amber-400 mb-2">Money-back guarantee</h1>
                        <p className="text-lg font-attribute-mono text-white">
                            We offer a money-back guarantee. If you're not satisfied, you can request a refund within 3 days of payment receipt, no questions asked. Refund requests can be made through our ticket system on the Discord server.
                        </p>
                    </div>

                    <div data-aos={"fade-right"} className="border-2 border-amber-600 rounded-lg p-4 mb-4 pl-8">
                        <h1 className="text-3xl font-cubano text-amber-400 mb-2">Losing VIP Status</h1>
                        <p className="text-lg font-attribute-mono text-white">
                            VIP status does not exempt you from following the rules on BF4C servers. We reserve the right to revoke your VIP status without a refund if you violate the rules or abuse the VIP commands/perks. By making a payment, you agree to abide by our rules.
                        </p>
                    </div>
                </div>
            </div>

            <div className={"text-center mt-8"}>
                <h1 className={"text-3xl font-cubano text-amber-500 md:text-5xl"}>Do you have questions or concerns?</h1>
                <p className={"text-lg font-attribute-mono text-white"}>
                    Need a help? We will be happy to help you on our Discord server.
                </p>
                <a href="https://discord.bf4c.net" className="inline-flex items-center bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-xl text-white font-attribute-mono font-bold p-2 rounded mt-4 transition-transform hover:scale-105">
                    <img src={require("../Images/discord-white-icon.png")} alt="Discord Logo" className="h-10 w-10 mr-4"/>
                    Join our Discord
                </a>
            </div>
        </div>
    );
}

export default VIP;
