import React from 'react';

const Home = () => {
    return (
        <div className="bg-gradient-to-b from-zinc-900 to-zinc-700 min-h-screen p-4">
            <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center">
                <div className="lg:w-1/2 p-4">
                    <h1 className="text-4xl text-amber-600 font-cubano mb-4">About Battlefield 4 Community</h1>
                    <p className="text-lg font-sofia-pro text-gray-50">
                        Are you tired of playing Battlefield 4 alone? Look no further! BF4C is the go-to PC Discord community for all BF4 players, from seasoned pros to beginners.
                        We welcome all playstyles and, most importantly, everyone is treated with respect. Dive into epic battles, strategize with teammates, and share unforgettable moments with fellow gamers.
                    </p>
                    <a href="https://discord.bf4c.net/"
                       className="inline-flex items-center justify-center px-4 py-2 mt-4 text-2xl font-mono font-bold bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-white rounded transition-transform hover:scale-105">
                        <img src={require("../Images/discord-white-icon.png")} alt="Discord Logo" className="h-10 w-10 mr-4"/>
                        Join Us Now!
                    </a>
                </div>

                <div className="lg:w-1/2 p-4">
                    <img
                        src={require("../Images/DragonPass.png")}
                        alt={"DragonPass"}
                        className={"w-full h-auto rounded-lg shadow-lg"}
                    />
                </div>
            </div>

            <h2 className="text-5xl text-center font-cubano bg-clip-text text-transparent bg-blue-600 mb-4 mt-24">Why should you become one of us?</h2>
            <div className="container mx-auto mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 cursor-pointer select-none">
                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/FirestormSoldiers.jpg")}
                        alt={"Firestorm"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">Diversity</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">We are a primarily European-based group, but we have people all around the globe! Everyone gets treated equally and with adequate respect. Embrace our diverse gaming community, uniting players from around the world in a respectful and inclusive atmosphere.</p>
                </div>

                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/Hangar21Heli.jpg")}
                        alt={"Hangar21"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">PC servers</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">Try our well-managed and known <a href={"/servers"} className={"bg-clip-text text-transparent bg-cyan-400"}><span className="border-b-2 border-blue-500">servers</span></a>. We have built a professional and non-abusive admin team ready to assist you and combat rule-breaking players daily! Be sure to try our flagship Conquest Large server or our newest Infantry-focused server.</p>
                </div>

                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/SunkenDragonSoldier.jpg")}
                        alt={"SunkenDragon"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">Gaming evenings</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">Multiple times a week, we organize gaming evenings! We meet in the voice channels in our Discord server and spend several hours having fun together on the in-game servers. We embrace team work and enjoy the true Battlefield experience. Claim the BF4C Gaming Evening role to get notified in advance.</p>
                </div>

                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/SilkRoadTankShot.jpg")}
                        alt={"SilkRoad"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">Ability to help</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">Ever since we were established back in 2019, we always relied on help from our community. Our PC servers, Discord server and the community as a whole have to be managed and taken care of. That's why we always have staff positions <a href={"/recruitment"} className={"bg-clip-text text-transparent bg-cyan-400"}><span className="border-b-2 border-blue-500">open to you</span></a> so you can grow and learn while helping BF4C.</p>
                </div>

                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/FirestormJet.jpg")}
                        alt={"Firestorm"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">Frequent giveaways</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">As a token of our appreciation for every single member, we host frequent giveaways. Let it be a BF4C VIP, Discord Nitro or anything valuable. There is always going to be the chance for you to win.</p>
                </div>

                <div className="bg-zinc-800 p-4 rounded-lg shadow-lg">
                    <img
                        src={require("../Images/OperationWhiteoutChopper.jpg")}
                        alt={"Whiteout"}
                        className={"w-full h-auto rounded-lg"}
                    />
                    <h2 className="text-4xl font-cubano text-emerald-600 mt-4">Find your new mates</h2>
                    <p className="text-gray-300 font-sofia-pro mt-2">BF4C consists of like-minded individuals who still admire and enjoy Battlefield 4 after all these years. As long as you will want to engage, you will get to make new friends to chat and play with. On top of that you will never have to play Battlefield 4 or another games alone. The choice is yours.</p>
                </div>
            </div>

            <div className="text-center mt-12">
                <h2 className="text-5xl font-cubano bg-clip-text text-transparent bg-green-500 mb-4">What are you waiting for?</h2>
                <a href="https://discord.bf4c.net/" className="inline-flex items-center justify-center px-4 py-2 text-2xl font-mono font-bold bg-gradient-to-r from-blue-700 via-blue-600 to-blue-500 text-white rounded transition-transform hover:scale-105">
                    <img src={require("../Images/discord-white-icon.png")} alt="Discord Logo" className="h-10 w-10 mr-4"/>
                    Join Us Now!
                </a>
            </div>

            <p className="text-sm text-right text-gray-200 mt-24">
                Images on the website are used from <a href="https://www.flickr.com/photos/berduu/albums/72157645981486278" className="text-blue-500 underline">Berdu's Flickr</a> and <a href="https://www.flickr.com/photos/cashplaybf22/albums/72177720297366736" className="text-blue-500 underline">Cashplaybf22's Flickr</a>.
            </p>
        </div>
    );
};

export default Home;
