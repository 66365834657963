const Footer = () => {
    return (
        <footer className="bg-zinc-800 py-4">
            <div className="text-center">
                <p className="text-white text-xl font-sofia-pro">{new Date().getFullYear()} @ BF4C</p>
            </div>
        </footer>
    )
}

export default Footer;
