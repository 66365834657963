import React, { useState } from 'react';

function Navbar() {
    const [isOpen, setIsOpen] = useState(true);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-zinc-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-32">
                    <div className="flex-shrink-0">
                        <a href={"/"}>
                            <img
                                src={require("../Images/BF4CLogo.gif")}
                                alt={"BF4C LOGO"}
                                className={"h-24 rounded"}
                            />
                        </a>
                    </div>
                    <div className="md:hidden">
                        <button onClick={toggleNavbar} className="text-white hover:text-white focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7"/>
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                            <a href="/servers" className="text-white font-cubano hover:bg-zinc-700 px-3 py-2 rounded-md text-2xl font-medium">Servers</a>
                            <a href="/vip" className="text-white font-cubano hover:bg-zinc-700 px-3 py-2 rounded-md text-2xl font-medium">VIP</a>
                            <a href="/recruitment" className="text-white font-cubano hover:bg-zinc-700 px-3 py-2 rounded-md text-2xl font-medium">Admin Recruitment</a>
                            <a href="https://linktr.ee/bf4c" className="text-white font-cubano hover:bg-zinc-700 px-3 py-2 rounded-md text-2xl font-medium">Social media</a>
                            <a href="https://stats.bf4c.net" className="text-white font-cubano hover:bg-zinc-700 px-3 py-2 rounded-md text-2xl font-medium">Stats</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden" style={{ maxHeight: isOpen ? '100vh' : '0', overflow: 'hidden', transition: 'max-height 0.5s ease-in-out' }}>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <a href="/servers" className="text-white font-cubano hover:bg-zinc-700 block px-3 py-2 rounded-md text-2xl font-medium">Servers</a>
                    <a href="/vip" className="text-white font-cubano hover:bg-zinc-700 block px-3 py-2 rounded-md text-2xl font-medium">VIP</a>
                    <a href="/recruitment" className="text-white font-cubano hover:bg-zinc-700 block px-3 py-2 rounded-md text-2xl font-medium">Admin Recruitment</a>
                    <a href="https://linktr.ee/bf4c" className="text-white font-cubano hover:bg-zinc-700 block px-3 py-2 rounded-md text-2xl font-medium">Social media</a>
                    <a href="https://stats.bf4c.net" className="text-white font-cubano hover:bg-zinc-700 block px-3 py-2 rounded-md text-2xl font-medium">Stats</a>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
